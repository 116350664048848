<template>
  <div class="container">
    <button
      class="toggle-btn"
      :class="{ active: isEmailShown }"
      type="button"
      @click="toggleEmailNotification"
    >
      <span></span>
      <span>Присылать Email уведомления о новых тендерах</span>
    </button>
  </div>
</template>

<script>

export default {
  name: "ToggleShowEmail",
  props: {
    isEmailShown: {
      type: Boolean
    },
  },
  components: {
  },
  data() {
    return {

    };
  },
  computed: {},
  methods: {
    toggleEmailNotification() {
      this.$emit("change", !this.isEmailShown);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/variables.less";

.container{
    display: flex;
    gap: 10px;
}
p {
  letter-spacing: 0.3px;
  color: white;
}

.toggle-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    &:first-child {
      position: relative;
      display: block;
      margin-right: 16px;
      border-radius: 12px;
      min-width: 48px;
      height: 24px;
      background-color: @bg-color-middle;
      transition: @trans;

      &::before {
        content: "";
        position: absolute;
        left: 3px;
        top: 2px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        transition: @trans;
      }
    }
  }

  &.active {
    span:first-child {
      background-color: @bg-color-dark;

      &::before {
        left: 25px;
      }
    }
  }
}
</style>
