const dayMapping = {
  "ПН": "MONDAY",
  "ВТ": "TUESDAY",
  "СР": "WEDNESDAY",
  "ЧТ": "THURSDAY",
  "ПТ": "FRIDAY",
  "СБ": "SATURDAY",
  "ВС": "SUNDAY"
};

const dayMappingRus = {
  "MONDAY" : "ПН",
  "TUESDAY" : "ВТ",
  "WEDNESDAY" : "СР",
  "THURSDAY" : "ЧТ",
  "FRIDAY" : "ПТ",
  "SATURDAY" : "СБ",
  "SUNDAY" : "ВС"
};

export function convertDaysToEnglish(days) {
  return days.map(day => dayMapping[day]);
}

export function convertDaysToRussian(days) {
  return days.map(day => dayMappingRus[day]);
}
